.location{
  padding: 10px 10px;
}

.locations-container {
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  margin: auto;
  align-items:center;
}

.locations-info {
  display: flex;
  flex-flow: row wrap;
  align-self: space-between;
  justify-content: center;
}

.gallery {
  flex-grow:0;
  flex-shrink:0;
  flex-basis:calc(50% - 10px);
  max-width: 250px;
}

.gallery a img:hover {
  -webkit-transform: scale(1);
	transform: scale(1.1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.fa {
  font-size: 16px;
}
.about-container {
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  margin: auto;
  align-items:center;
}

.middlethis {
  display: flex;
  flex-flow: row wrap;
  align-self: space-between;
  justify-content: space-between;
}

.gallery:hover{
  opacity:0.25;
  
}

div.gallery img {
  display: inline;
    margin: 5px;
  max-height: 250px;
  max-width: 200px;
  min-height: 250px;
  object-fit: cover;
}

.gallery {
  flex-grow:0;
  flex-shrink:0;
  flex-basis:calc(50% - 10px);
  max-width: 300px;
}

@media only screen and (max-width: 700px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}

.about {
  max-width: 900px;
  text-align: left;
  margin: auto;
}

.bio-item {
  justify-content: center;
}

.bio-container {
  display: flex;
  flex-direction: row;
  min-height: 510px;
  max-width: 900px;
  margin-top: 50px;
}

.bio-image {
  width: 300px;
  height: 400px;
  object-fit: cover;
}

.title {
  font-size: 22px;
}

.bio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 65px;
  padding-left: 100px;
  text-align: left;
}

